html {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.TitleContainer {
  width: 600px;
  height: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Title {
  font-size: 3.4em;
  text-align: center;
  font-family: 'Bungee', cursive;
  padding: 20px;
  color: #ff9a00;
  text-shadow: horizontal-offset vertical-offset blur color;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}

@media only screen and (min-width: 630px) {
    .Title {
        font-size:5.5em;
    }
}

.TimerContainer {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 400px;
  font-size: 7em;
}

input:focus{
    outline: none;
}

*:focus {
    outline: none;
}

.TimerMins {
  text-align: right;
}

.TimerSecs {
  text-align: left;
}

input {
  border: none;
  height: 150px;
  width: 50%;

}

input[value] {
  font-size: 1em;
  font-family: 'VT323', monospace;
}

input:disabled {
  background-color: white;
}

.Show {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 600px;
  height: 145px;
}

.Hidden {
  display: none;
}

.TextBubble {
  padding: 0.1%;
  text-align: center;
  font-family: 'Rock Salt', cursive;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
	position: relative;
  width: 40%;
  height: 100px;
	background: #474b52;
  color: #ff9a00;
	border-radius: .4em;
}

.TextBubble:after {
  content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 0.625em solid transparent;
	border-left-color: #474b52;
	border-right: 0;
	margin-top: -0.625em;
	margin-right: -0.625em;
}

.Avatar {
  margin-left: 5%;
  height: 75px;
  width: 75px;
}

.ButtonContainer{
  margin: 1%;
  display: flex;
  flex-direction: row;
}

.Button {
  margin: 5% 5%;
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.24);
}

.Button:hover {
  cursor: pointer;
}

.Button:active {
  box-shadow: 0 0 0;
  transform: translateY(4px);
}

.StartB {
  background-color: #00a300;
}

.StopB {
  background-color: #ee1111;
}

.PauseB {
  background-color: #ffc40d;
}

.twitchIco {
  margin-left: 1%;
  margin-right: 4%;
}

.SoundContainer {
  width: 390px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.volRow{

}

.ButtonRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sButton {
  margin: 10px;
  width: 45px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.24);
}

.sButton:hover {
  cursor: pointer;
}

.sButton:active {
  box-shadow: 0 0 0;
  transform: translateY(4px);
}

.tableTitle {
  text-align: center;
}

.runeTable {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5em;
}

.runeCol {
  width: 120px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
}

.runeTitle {
  width: 100%;
  color: 	#ff9a00;
  text-align: center;
  font-size: 0.8em;
}

.runeRow {
  width: 100%;
  text-align: center;
}

.odd {
  background-color: #EEEEEE;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.highlight {
  color: #ff9a00;
}

footer {
  margin-top: 5%;
  padding: 3%;
  width: 100%;
  text-align: center;
}

.description {
  width: 400px;
  text-align: center;
}
